<script lang="ts" setup>
import type { NotificationMessage, NotificationMessagePayload } from "@/types";

const MAX_TITLE_LENGTH = 32;
const MAX_MESSAGE_LENGTH = 64;

const props = defineProps<{
	data: NotificationMessage;
}>();

const emit = defineEmits<{
	(event: "clickMessage", payload: NotificationMessagePayload): void;
}>();

const { t } = useT();
const { durationLeft, duration } = useCountdown({ timestamp: props.data.dateEnd });
const [isShowAll, toggleShowAll] = useToggle(false);

const isShowTimer = computed(() => {
	if (props.data.type !== "offer") {
		return false;
	}

	const isStartLessNow = new Date(props.data.dateBegin).getTime() <= new Date().getTime();
	const isEndMoreNow = new Date(props.data.dateEnd).getTime() > new Date().getTime();

	return isStartLessNow && isEndMoreNow;
});
const isLongMessage = computed(
	() => props.data.message?.length > MAX_MESSAGE_LENGTH || props.data.title?.length > MAX_TITLE_LENGTH
);

const handleClickOnMessage = ({
	needSendStatusToServer = false,
	shouldOpenLink = false
}: Partial<Omit<NotificationMessagePayload, "id">> = {}) => {
	if (isLongMessage.value && !isShowAll.value) {
		toggleShowAll();
		return;
	}

	dispatchGAEvent({
		event: "click_button",
		location: props.data.isReaded ? "earlier" : "new",
		button_name: "notification",
		type: props.data.systemAlias
	});

	emit("clickMessage", { id: props.data.ncMessageId, needSendStatusToServer, shouldOpenLink });
};

watch(duration, () => {
	if (duration.value < 1) {
		handleClickOnMessage();
	}
});
</script>
<template>
	<div
		class="notification-item"
		:class="{ 'is-read': data.isReaded, 'is-long-text': isLongMessage && !isShowAll }"
		@click.stop="handleClickOnMessage({ needSendStatusToServer: true, shouldOpenLink: true })"
	>
		<img :src="data.image?.replace('http:', 'https:')" :alt="data.title" width="36" height="36" />
		<div class="content">
			<AText class="title" :modifiers="['semibold']">{{ data.title }}</AText>
			<AText as="p" class="message text-conakry" variant="topeka">{{ data.message }}</AText>
			<AText
				v-if="isLongMessage"
				class="more"
				variant="topeka"
				:modifiers="['link', 'underline']"
				@click.stop="toggleShowAll()"
			>
				{{ isShowAll ? t("Less details") : t("More details") }}
			</AText>
		</div>
		<div class="action">
			<AButton v-if="data.link" variant="primary">
				<AText variant="topeka" :modifiers="['semibold']">{{ data.buttonLabel || t("More") }}</AText>
			</AButton>
			<AText v-if="isShowTimer && duration" variant="ternopil">
				<i18n-t keypath="{key} left">
					<template #key>
						{{ durationLeft }}
					</template>
				</i18n-t>
			</AText>
		</div>
	</div>
</template>
<style scoped lang="scss">
.notification-item {
	padding: gutter(0.5) gutter(2);
	display: flex;
	align-items: center;
	gap: gutter(1);
	border-bottom: 1px solid var(--colombo);
	cursor: pointer;

	&.is-read {
		cursor: auto;
	}

	&.is-long-text {
		.title,
		.message {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.more {
			display: inline-block;
			color: #018ff3;
			margin-top: gutter(0.25);
		}
	}

	img {
		flex-shrink: 0;
	}

	button {
		padding: gutter(1) gutter(2);
	}

	.content {
		flex: 1;
	}

	.action {
		display: flex;
		flex-direction: column;
		align-items: center;

		span.ternopil {
			color: var(--cebal);
			font-variant-numeric: tabular-nums;
		}
	}
}
</style>
